<template>
  <div>
    <v-combobox
      v-model="comboModel"
      :hide-no-data="!groupSearch"
      :items="comboList"
      :search-input.sync="groupSearch"
      :filter="filter"
      :label="label"
      item-text="text"
      class="my-2"
      elevation="0"
      hide-selected
      hide-details
      :outlined="outlined"
      :dense="dense"
      :solo="solo"
      :filled="filled"
      :rounded="rounded"
      :disabled="disabled"
      @keyup.enter="addNewGroup"
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Nuevo grupo: <strong>{{ groupSearch }}</strong>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-slot:item="{ index, item }">
        <v-row>
          <v-col cols="9">
            <v-text-field
              @click.stop
              v-if="groupEditing === item"
              v-model="groupEditing.text"
              flat
              background-color="transparent"
              hide-details
              solo
            ></v-text-field>
            <span v-else style="width: 90%">
              {{ item.text }}
            </span>
          </v-col>
          <v-col cols="3" @click.stop class="d-flex align-center justify-end">
            <v-btn icon @click="handleGroupEdit(index, item)">
              <v-icon>{{
                groupEditing !== item ? "mdi-pencil" : "mdi-check"
              }}</v-icon>
            </v-btn>
            <v-btn icon @click="removeGroup(item)" v-if="groupEditing == item">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <!-- @keyup.enter="edit(index, item)" -->
        <!-- <v-spacer></v-spacer> -->
      </template>
    </v-combobox>
  </div>
</template>
<script>
export default {
  props: {
    comboList: {
      type: Array,
    },
    comboType: {
      type: String,
      default: "",
    },
    defVal: {
      type: Object,
      default: null,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      comboModel: this.defVal,
      // localList: [...this.comboList],
      groupSearch: null,
      groupEditing: null,
      prevGroupEditing: "",
      groupEditingIndex: -1,
    };
  },
  watch: {
    comboModel: {
      deep: true,
      handler(organizator) {
        if (organizator?.value) {
          this.$emit("setUtility", {
            newValue: organizator.value,
            updateList: false,
          });
        }
      },
    },
  },
  methods: {
    async addNewGroup() {
      let alreadyExist = [...this.comboList].some(
        (item) => item.value === this.groupSearch
      );
      if (alreadyExist) return;
      try {
        let response = await this.axios({
          method: "POST",
          url: "/utilities/post-utility",
          data: {
            newItem: this.groupSearch,
            type: this.comboType,
          },
        });
        if (response.status == 200) {
          this.$emit("setUtility", {
            newValue: this.comboModel.value,
            updateList: true,
          });
          // this.comboModel = response.data.newItem
          // this.groupSearch = response.data.newItem.value
        }
      } catch (error) {
        console.log(error);
      }
    },
    async handleGroupEdit(index, item) {
      if (!this.groupEditing) {
        this.prevGroupEditing = item;
        this.groupEditing = item;
        this.groupEditingIndex = index;
      } else {
        if (item.text === item.value) {
          this.resetGroupEditingStatus()
          return;
        }
        try {
          let response = await this.axios({
            method: "PUT",
            url: "/utilities/update-utility",
            data: {
              item: item.value,
              update: item.text,
              type: this.comboType,
            },
          });

          if (response.status == 200) {
            this.$emit("setUtility", { newValue: item.text, updateList: true });
          }
        } catch (error) {
          console.log(error);
        } finally {
          this.resetGroupEditingStatus()
        }
      }
    },
    async removeGroup(item) {
      try {
        await this.axios({
          method: "DELETE",
          url: "/utilities/delete-utility",
          data: {
            item: item.value,
            type: "IMEF_GROUP",
          },
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.$emit("setUtility", {
          newValue: this.comboModel.value,
          updateList: true,
        });
        this.resetGroupEditingStatus()
      }
    },
    resetGroupEditingStatus() {
      this.groupEditing = null;
      this.groupEditingIndex = -1;
      this.prevGroupEditing = "";
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText).toString().toLowerCase();
      const query = hasValue(queryText).toString().toLowerCase();

      return text.includes(query);
    },
  },
};
</script>
